import * as React from 'react';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { useTheme } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import { FilledCheckIcon } from 'src/assets/icons';
import { COLORS } from 'src/constants';
import { FormControl, Select, MENU_SX } from './SelectInput.styled';
import { StyledMenuItem } from '../../OptionsMenu/OptionsMenu.styled';
import { P16 } from '../../Typography';
import { StyledInputRequiredStar } from '../text-input/TextInput.styled';
import { DropdownIcon } from './DropdownIcon';

export interface SelectOptionItem {
  /** A label displayed on the ui, should be internalised */
  label: string;
  /** A unique key to identify the selection option in onSelectOption callback */
  value: string;
  /** If true, the option will be displayed in red color */
  danger?: boolean;
  /** If true the option will not be selectable */
  disabled?: boolean;
}

export type SelectInputProps = Partial<SelectProps> & {
  /** if true, iput height will be smaller */
  small?: boolean;
  /** If true, value must be an array and the menu will support multiple selections. */
  multiple?: boolean;
  /** change event triggered when a new value is selected */
  onChange?: (event: SelectChangeEvent<unknown>) => void;
  /** helper to transform the selected value   */
  renderValue?: (value: unknown) => React.ReactNode;
  /** Name of the input */
  name: string;
  /** Label of the input */
  label?: string;
  /** Set to true to show red asterisk on the label */
  required?: boolean;
  /** An I con to display at the far left of the select input */
  startAdornment?: SelectProps['startAdornment'];
  /** A list of option with
   * - label: the label to display on the ui, should be internalised
   * - value: a unique key to identify the selection option in onSelectOption callback
   * - danger: if true, the option will be displayed in red color
   * - disabled: If true the option will not be selectable
   */
  options: readonly SelectOptionItem[];
  /** The default place holder that will be show in the input selection */
  defaultValue?: string;
  isDirty?: boolean;
};

export const SelectInput: React.FunctionComponent<SelectInputProps> = ({
  small,
  renderValue,
  onChange,
  value,
  multiple,
  required,
  label,
  name,
  startAdornment,
  options,
  isDirty,
  defaultValue,
  ...rest
}) => {
  const theme = useTheme();
  const getMenuStyles = MENU_SX(theme);
  const { t } = useTranslation();

  return (
    <div className="select-input">
      {!isEmpty(label) && (
        <P16 as="label" htmlFor={name} $margin="0 0 6px 0" $display="block" $fontWeight={500}>
          {t(label as string)}&nbsp;{required && <StyledInputRequiredStar>*</StyledInputRequiredStar>}
        </P16>
      )}
      <FormControl fullWidth>
        <Select
          label={null}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          IconComponent={DropdownIcon}
          renderValue={renderValue}
          defaultValue={defaultValue || ''}
          multiple={multiple}
          MenuProps={{
            sx: getMenuStyles,
          }}
          startAdornment={startAdornment}
          $isDirty={isDirty}
          {...(small && { size: 'small' })}
          {...rest}
        >
          {options.map(({ label: itemLabel, value: itemValue, danger, disabled }) => {
            const baseLabel = (itemLabel?.includes(':') ? itemLabel.split(':')[0] : itemLabel) || '';

            const parsedLabel = capitalize(baseLabel);

            return (
              <StyledMenuItem
                key={itemValue}
                value={itemValue}
                $danger={danger}
                disabled={disabled}
                $isMultiple={multiple}
              >
                <div>
                  <span title={parsedLabel}>{parsedLabel}</span> <FilledCheckIcon fill={COLORS.primaryDark} />
                </div>
              </StyledMenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

SelectInput.defaultProps = {
  small: false,
  multiple: false,
  renderValue: undefined,
  label: '',
  required: false,
  startAdornment: undefined,
  isDirty: false,
  onChange: undefined,
  defaultValue: '',
};
